import { Card, Tabs } from "@sundae/ui-toolkit";
import { FC } from "react";
import cx from "classnames";

import { useReactQuerySubscription } from "../../hooks/useReactQuerySubscription";
import LoadingState from "../LoadingState";
import NoDataIndicator from "../NoDataIndicator";
import { Order } from "./Order";

const OrdersView: FC = () => {
  const { ordersData, fetchingOrders, myOrdersData, fetchingMyOrders } =
    useReactQuerySubscription();

  return (
    <Card bg="default" className="flex-1">
      <div className="flex flex-col gap-6">
        <Tabs
          defaultValue="0"
          data={[
            {
              id: "0",
              trigger: "Network Orders",
              content: (
                <div
                  className={cx("mt-4 flex min-h-[200px] w-full flex-col justify-start", {
                    "items-center": fetchingOrders,
                  })}
                >
                  {fetchingOrders && !ordersData?.length && <LoadingState size="medium" />}

                  {!fetchingOrders && !ordersData?.length && (
                    <NoDataIndicator size="sm" label="No Orders found." />
                  )}

                  {!fetchingOrders && ordersData?.length && (
                    <div className="flex max-h-[394px] w-full animate-dialog-overlay-show flex-col gap-4 overflow-y-auto">
                      {ordersData?.map((data) => (
                        <Order key={data.key} {...data} />
                      ))}
                    </div>
                  )}
                </div>
              ),
            },
            {
              id: "1",
              trigger: "My Orders",
              content: (
                <div
                  className={cx("mt-4 flex min-h-[200px] w-full flex-col justify-start", {
                    "items-center": fetchingMyOrders,
                  })}
                >
                  {fetchingMyOrders && !myOrdersData?.length && <LoadingState size="medium" />}

                  {!fetchingMyOrders && !myOrdersData?.length && (
                    <NoDataIndicator size="sm" label="No Orders found." />
                  )}

                  {!fetchingMyOrders && myOrdersData?.length && (
                    <div className="flex max-h-[394px] w-full animate-dialog-overlay-show flex-col gap-4 overflow-y-auto">
                      {myOrdersData?.map((data) => (
                        <Order key={data.key} {...data} />
                      ))}
                    </div>
                  )}
                </div>
              ),
            },
          ]}
        />
      </div>
    </Card>
  );
};

export default OrdersView;
