import { Outlet, ReactLocation, Router } from "@tanstack/react-location";
import { FC, Suspense } from "react";
import Header from "../components/Header";
import Layout from "../components/Layout";
import LoadingState from "../components/LoadingState";

import { AppRoutes } from "./routes";

interface IRoutesProps {
  location: ReactLocation;
}

export const Routes: FC<IRoutesProps> = ({ location }): JSX.Element => {
  return (
    <Router useErrorBoundary location={location} routes={AppRoutes}>
      <Header />
      <Layout>
        <Suspense fallback={<LoadingState />}>
          <Outlet />
        </Suspense>
      </Layout>
    </Router>
  );
};
