/* eslint-disable */
import { useCallback, useState } from "react";
import { Lucid, Constr, Data } from "lucid-cardano";

import { useTempWallet } from "./useTempWallet";
import { toLovelace } from "../utils/conversions";
import { useEscrowAddress } from "./useEscrowAddress";

interface ISwapAssetsArgs {
  assetAAmount: number;
  assetAMetadata: Record<string, any>;
  assetBAmount: number;
  assetBMetadata: Record<string, any>;
}

export interface ISwapPending extends ISwapAssetsArgs {
  hash: string;
}

type ISwapAssetsFunc = (args: ISwapAssetsArgs) => Promise<string>;

export const PENDING_SWAPS_KEY = "-pending-swaps";

export const useSwap = (lucidClient: Lucid) => {
  const { wallet } = useTempWallet();
  const { escrowAddress } = useEscrowAddress();
  const [swapping, setSwapping] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  const swapAssets = useCallback<ISwapAssetsFunc>(
    async ({ assetAAmount, assetAMetadata }) => {
      if (!wallet) {
        return;
      }

      const data = new Constr(0, [
        "00",
        new Constr(0, [
          new Constr(0, [
            new Constr(0, [new Constr(0, [wallet.hash]), new Constr(1, [])]),
            new Constr(1, []),
          ]),
          new Constr(1, []),
        ]),
        "0n",
        new Constr(0, [
          new Constr(assetAMetadata.meta === "ada" ? 0 : 1, []),
          `${toLovelace(assetAAmount, assetAMetadata.decimals)}n`,
          new Constr(0, [`${1}n`]),
        ]),
      ]);

      setSwapping(true);

      try {
        const tx = lucidClient.newTx();
        const payment: Record<string, bigint> = {};
        if (assetAMetadata.meta === "ada") {
          payment.lovelace = BigInt(2000000) + BigInt(toLovelace(assetAAmount, 6));
        } else {
          payment.lovelace = BigInt(2000000);
          payment[assetAMetadata.assetId.replace(".", "")] = BigInt(toLovelace(assetAAmount, 6));
        }

        tx.payToContract(escrowAddress, Data.to(data), payment);

        const finishedTx = await tx.complete();
        const signedTx = await finishedTx.sign().complete();
        const hash = await signedTx.submit();
        setSwapping(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
        return hash;
      } catch (e) {
        setSwapping(false);
        switch (e) {
          case "Insufficient input in transaction":
          case "InputsExhaustedError":
            setError("You don't have enough of that asset!");
        }
        console.log(e);
      }
    },
    [lucidClient, wallet, escrowAddress]
  );

  return { swapAssets, swapping, error, success, setError };
};
