import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type DateTime = {
  __typename?: "DateTime";
  format: Scalars["String"];
};

export type DateTimeFormatArgs = {
  layout?: InputMaybe<Scalars["String"]>;
};

export type Heartbeat = {
  __typename?: "Heartbeat";
  /**  300 buckets for the number of orders in a given second  */
  gummiCandles: Array<Scalars["Int"]>;
  /**  Number of orders in that period on the gummiworm testnet  */
  gummiOrders: Scalars["Int"];
  /**  300 buckets for the number of orders in a given second  */
  previewCandles: Array<Scalars["Int"]>;
  /**  Number of orders in that period on the preview testnet  */
  previewOrders: Scalars["Int"];
  /**  Number of seconds this covers  */
  span: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  fund: Scalars["String"];
  /**  Submit a transaction to the hydra node  */
  submit: Scalars["String"];
};

export type MutationFundArgs = {
  addresses: Array<Scalars["String"]>;
  amount?: InputMaybe<Scalars["Int"]>;
};

export type MutationSubmitArgs = {
  cborHex: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  /** A ping to check server health */
  ok: Scalars["String"];
  /**  Query the UTXOs currently held by address  */
  utxos: Array<Utxo>;
};

export type QueryUtxosArgs = {
  address: Scalars["String"];
};

export type Subscription = {
  __typename?: "Subscription";
  /**  Subscribe to a heartbeat of statistics  */
  heartbeat: Heartbeat;
  /**  Subscribe to all swaps for a particular address  */
  listenForSwaps: SwapEvent;
};

export type SubscriptionListenForSwapsArgs = {
  address?: InputMaybe<Scalars["String"]>;
};

export type SwapEvent = {
  __typename?: "SwapEvent";
  /**  The address the order was for  */
  address: Scalars["String"];
  /**  The new price after the trade  */
  newPrice: Scalars["Float"];
  /**  The old price before the trade  */
  oldPrice: Scalars["Float"];
  /**  The transaction where the order was scooped  */
  scoop: Scalars["String"];
  /**  The time the swap got executed  */
  timestamp: DateTime;
  /**  The (assetId) of tokenA that was swapped  */
  tokenA: Scalars["String"];
  /**  The amount of A that was swapped  */
  tokenAAmt: Scalars["String"];
  /**  The (assetId) of tokenB that was swapped  */
  tokenB: Scalars["String"];
  /**  The amount of B that was received  */
  tokenBAmt: Scalars["String"];
  /**  The Transaction ID  */
  transaction: Scalars["String"];
};

export type Token = {
  __typename?: "Token";
  /**  The assetId (policyId.assetHex) of the token  */
  assetId: Scalars["String"];
  /**  Quantity of token, potentially large  */
  quantity: Scalars["String"];
};

export type Utxo = {
  __typename?: "UTXO";
  /**  The address the UTXO is at  */
  address: Scalars["String"];
  /**  Any tokens on the UTXO  */
  assets: Array<Token>;
  /**  The index of the UTXO in the transaction  */
  index: Scalars["Int"];
  /**  The lovelace  */
  lovelace: Scalars["String"];
  /**  The tx that produced this UTXO  */
  transactionHash: Scalars["String"];
};

export type SubmitTxMutationVariables = Exact<{
  cborHex: Scalars["String"];
}>;

export type SubmitTxMutation = { __typename?: "Mutation"; submit: string };

export type FundMutationVariables = Exact<{
  addresses: Array<Scalars["String"]> | Scalars["String"];
}>;

export type FundMutation = { __typename?: "Mutation"; fund: string };

export type GetUtxosQueryVariables = Exact<{
  address: Scalars["String"];
}>;

export type GetUtxosQuery = {
  __typename?: "Query";
  utxos: Array<{
    __typename?: "UTXO";
    transactionHash: string;
    index: number;
    address: string;
    lovelace: string;
    assets: Array<{ __typename?: "Token"; assetId: string; quantity: string }>;
  }>;
};

export const SubmitTxDocument = gql`
  mutation submitTx($cborHex: String!) {
    submit(cborHex: $cborHex)
  }
`;
export const FundDocument = gql`
  mutation fund($addresses: [String!]!) {
    fund(addresses: $addresses)
  }
`;
export const GetUtxosDocument = gql`
  query getUtxos($address: String!) {
    utxos(address: $address) {
      transactionHash
      index
      address
      lovelace
      assets {
        assetId
        quantity
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    submitTx(
      variables: SubmitTxMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SubmitTxMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubmitTxMutation>(SubmitTxDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "submitTx",
        "mutation"
      );
    },
    fund(
      variables: FundMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<FundMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FundMutation>(FundDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "fund",
        "mutation"
      );
    },
    getUtxos(
      variables: GetUtxosQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GetUtxosQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUtxosQuery>(GetUtxosDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getUtxos",
        "query"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
