import { getPublicKey, utils } from "@noble/ed25519";
import { useLocalStorageState } from "@sundae/react-hooks";
import { useEffect, useState } from "react";

export const KEY_PAIR_SLUG = "hydra-poc-temp-key-pair";

interface IKeyPair {
  privateKey: string;
  publicKey: string;
}

export const useTempKeyPair = () => {
  const [keyPair, setKeyPair, clearKeyPair] = useLocalStorageState<IKeyPair>(KEY_PAIR_SLUG);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    if (keyPair) {
      return;
    }

    (async () => {
      setIsGenerating(true);
      const privateKey = utils.randomPrivateKey();
      const publicKey = await getPublicKey(privateKey);

      setKeyPair({
        privateKey: utils.bytesToHex(privateKey),
        publicKey: utils.bytesToHex(publicKey),
      });
      setIsGenerating(false);
    })();
  }, [keyPair]);

  return {
    isGenerating,
    keyPair,
    setKeyPair,
    clearKeyPair,
  };
};
