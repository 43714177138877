import { useTheme } from "@sundae/react-hooks";
import { Card, Image, toolkitCx } from "@sundae/ui-toolkit";
import cx from "classnames";
import { FC, memo } from "react";
import { CombinedError } from "urql";
import ContentLoader from "../ContentLoader";
import NoDataIndicator from "../NoDataIndicator";

const {
  theme: { colors },
} = require("@sundae/tailwind-config");

interface IChartSkeleton {
  error?: CombinedError;
  height: number;
  showLoadingAnimation: boolean;
  width: number;
}

const Skeleton: FC<
  Omit<IChartSkeleton, "error" | "showLoadingAnimation"> & { isDarkMode: boolean }
> = memo(({ width, height, isDarkMode }) => (
  <ContentLoader
    className="absolute top-0 left-0"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundOpacity={0}
    gradientRatio={0.6}
    backgroundColor={isDarkMode ? colors.gray[900] : colors.gray.DEFAULT}
    foregroundColor={isDarkMode ? colors.gray[900] : colors.gray.DEFAULT}
  >
    <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
  </ContentLoader>
));

const ChartSkeleton: FC<IChartSkeleton> = memo(({ error, height, showLoadingAnimation, width }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";

  return (
    <div style={{ width, height }}>
      <Image
        className={cx("absolute bottom-0 left-0 z-[-1]", {
          "opacity-50": !isDarkMode,
          "opacity-75": isDarkMode,
        })}
        src="/static/images/chart/placeholder.svg"
      />

      {showLoadingAnimation && <Skeleton isDarkMode={isDarkMode} width={width} height={height} />}

      {error && !showLoadingAnimation && (
        <Card
          className={cx(
            "absolute animate-dialog-overlay-show text-center",
            toolkitCx.recipes.center.both()
          )}
        >
          <NoDataIndicator containerClassName="p-0" label={error.message} />
        </Card>
      )}
    </div>
  );
});

export default ChartSkeleton;
