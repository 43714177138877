import { useEffect } from "react";
import { useMutation } from "react-query";

export const useEscrowAddress = () => {
  const { data: escrowAddress, mutateAsync: fetchAddress } = useMutation(async () => {
    const { data } = await fetch("https://api.gummi.preview.sundaeswap.finance/query", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
            query {
                escrowAddress
            }
        `,
      }),
    }).then((res) => res.json());

    return data?.escrowAddress;
  });

  useEffect(() => {
    fetchAddress();
  }, []);

  return { fetchAddress, escrowAddress };
};
