import { Button, Header as UIHeader } from "@sundae/ui-toolkit";
import { IHeaderNavigationMap } from "@sundae/ui-toolkit/dist/types/components/organisms/Header/Header";
import { noop } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { EEX_PATHS, EPATHS } from "../../constants/paths";
import { getHydraSdk } from "../../gql/client/hydraSdk";
import { useBalance } from "../../hooks/useBalance";
import { useTempWallet } from "../../hooks/useTempWallet";

const Header = (): JSX.Element => {
  const navItems: IHeaderNavigationMap[] = useMemo(
    () => [
      {
        to: EPATHS.HOME,
        title: "Home",
      },
      {
        highlightLabel: "Real Time",
        to: EPATHS.PRICE,
        title: "Price",
      },
      {
        to: EEX_PATHS.WEBSITE,
        title: "About SundaeSwap",
        external: true,
      },
      {
        dropdown: {
          menuTitle: "Follow Us",
          menuContent: [
            {
              to: EEX_PATHS.TWITTER,
              title: "Twitter",
              description: "@SundaeSwap",
              external: true,
            },
            {
              to: EEX_PATHS.DISCORD,
              title: "Discord",
              description: "Join our SundaeSwap Community.",
              external: true,
            },
            {
              to: EEX_PATHS.MEDIUM,
              title: "Medium",
              description: "Follow our Medium for the latest updates.",
              external: true,
            },
          ],
        },
      },
    ],
    []
  );
  const [transparent, setTransparent] = useState(window.scrollY < 100);
  const { wallet } = useTempWallet();
  const { balance, initialized } = useBalance("lovelace", 6);
  const [funding, setFunding] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setTransparent(false);
      }

      if (window.scrollY < 100) {
        setTransparent(true);
      }
    });
  }, []);

  return (
    <UIHeader
      navigationItems={navItems}
      homepageLinkHref={EPATHS.HOME}
      homepageLinkTitle="Home | SundaeSwap Governance"
      isConnected={initialized}
      showThemeToggle={false}
      theme="dark"
      toggleTheme={noop}
      transparent={transparent}
      renderTertiaryNav={() => {
        return (
          initialized &&
          balance === 0 && (
            <Button
              variant="secondary"
              disabled={funding}
              onClick={async () => {
                if (!wallet?.address) {
                  return;
                }

                setFunding(true);
                const { fund } = await getHydraSdk();
                const { fund: status } = await fund({
                  addresses: [wallet?.address],
                });
                setTimeout(() => {
                  setFunding(false);

                  if (status === "success") {
                    window.location.reload();
                  }
                }, 1000);
              }}
            >
              {funding ? "Funding..." : "Fund Wallet"}
            </Button>
          )
        );
      }}
    />
  );
};

export default Header;
