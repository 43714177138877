import { CARDANO_ADA_ASSET_ID } from "@sundae/cardano-utils";

export const ADA_METADATA = {
  policyId: "",
  assetName: "Cardano",
  ticker: "ADA",
  assetId: CARDANO_ADA_ASSET_ID,
  decimals: 6,
  logo: "/static/images/cardano.png",
  symbol: "₳",
  meta: "ada",
};

export const RBERRY_METADATA = {
  assetId: "068a0b89fd5a94da98daf8afee2e16262f280175cf444085dd1d7da3.524245525259",
  assetName: "524245525259",
  decimals: 6,
  logo: "/static/images/rberry.png",
  policyId: "068a0b89fd5a94da98daf8afee2e16262f280175cf444085dd1d7da3",
  ticker: "RBERRY",
  symbol: "",
  meta: "rberry",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SWAP_ASSETS: Record<string, any> = {
  ada: ADA_METADATA,
  rberry: RBERRY_METADATA,
};
