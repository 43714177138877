import cx from "classnames";
import { FC, HTMLAttributes } from "react";
import ContentLoader from "../ContentLoader";

const StatsSkeleton: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  return (
    <ContentLoader
      height={72}
      width={72}
      className={cx("mx-auto rounded-lg", "animate-dialog-overlay-show", className)}
      viewBox="0 0 72 72"
    >
      <rect x="0" y="0" rx="0" ry="0" width={72} height={72} />
    </ContentLoader>
  );
};

export default StatsSkeleton;
