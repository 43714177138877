import { Route } from "@tanstack/react-location";
import { EPATHS } from "../constants/paths";

import Home from "./Home";
import Price from "./Price";

export const AppRoutes: Route[] = [
  {
    id: "home",
    path: EPATHS.HOME,
    element: <Home />,
    loader: () => ({
      align: "center",
    }),
  },
  {
    id: "price",
    path: EPATHS.PRICE,
    element: <Price />,
  },
];
