import { useClipboardCopy } from "@sundae/react-hooks";
import {
  Button,
  CheckIcon,
  Collapsible,
  CopyIcon,
  FlexList,
  FlexListItem,
  InfoCircledIcon,
  Loader,
  PlusIcon,
  Text,
  toolkitCx,
  Tooltip,
  VisuallyHidden,
} from "@sundae/ui-toolkit";
import cx from "classnames";
import { FC, useState } from "react";

import { useTempWallet } from "../../hooks/useTempWallet";
import { KEY_PAIR_SLUG, useTempKeyPair } from "../../hooks/useTempKeyPair";

const CopyText: FC<{ text: string; label: string }> = ({ text, label }) => {
  const { copy, copied } = useClipboardCopy({
    copiedTimeout: 500,
  });

  const Label = () => (
    <div
      className="flex cursor-pointer items-center justify-center gap-2"
      onClick={() => copy(text)}
    >
      <Text tag="span" size="sm">
        {text}
      </Text>
      {copied ? (
        <CheckIcon className="dark:text-white" />
      ) : (
        <CopyIcon className="dark:text-white" />
      )}
    </div>
  );

  return (
    <FlexListItem
      field={{ label, variant: "muted", size: "sm" }}
      value={{ label: <Label />, variant: "muted", size: "sm" }}
    />
  );
};

const DevTools: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isGenerating, keyPair } = useTempKeyPair();
  const { wallet, isCreatingWallet } = useTempWallet();
  const isLoading = isGenerating || isCreatingWallet;

  return (
    <div
      className={cx(
        "mt-2 w-full overflow-hidden rounded-lg",
        toolkitCx.layout.border.default,
        toolkitCx.layout.border.weight.default
      )}
    >
      <Collapsible
        onClick={() => {
          if (!isLoading) setIsOpen(!isOpen);
        }}
        open={isOpen}
        trigger={
          <div
            className={cx(
              "animate-dialog-overlay-show rounded-lg",
              "w-full",
              "select-none",
              "flex items-center justify-between",
              { "!bg-gray-200 dark:!bg-gray-700": isOpen },
              { "cursor-pointer": !isLoading },
              toolkitCx.layout.background.card,
              toolkitCx.layout.background.hoverable,
              toolkitCx.layout.transition.colors,
              toolkitCx.layout.spacing.vertical.sm,
              toolkitCx.layout.spacing.horizontal.md
            )}
          >
            {isLoading ? (
              <div className="flex items-baseline gap-6">
                <Text size="sm" variant="muted" tag="span">
                  Generating Wallet
                </Text>
                <Loader size="xsmall" />
              </div>
            ) : (
              <>
                <div className="flex items-center gap-1">
                  <Text tag="span" size="sm">
                    {isOpen ? "Close Dev Tools" : "Open Dev Tools"}
                  </Text>
                  <Tooltip
                    trigger={<InfoCircledIcon />}
                    tooltipLabel="Our Dev Tools show information that might be useful for developers."
                  />
                </div>

                <div>
                  <VisuallyHidden>{isOpen ? "Less" : "More"}</VisuallyHidden>
                  <PlusIcon
                    className={cx(
                      { "rotate-45": isOpen },
                      toolkitCx.layout.transition.transform,
                      toolkitCx.layout.text.default
                    )}
                  />
                </div>
              </>
            )}
          </div>
        }
      >
        <div
          className={cx(
            "w-full",
            toolkitCx.layout.spacing.vertical.sm,
            toolkitCx.layout.spacing.horizontal.md,
            toolkitCx.layout.background.card
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <FlexList>
            <CopyText text={keyPair?.privateKey} label={"Private Key"} />
            <CopyText text={keyPair?.publicKey} label="Public Key" />
            <CopyText text={wallet?.address} label="Wallet Address" />
          </FlexList>
          <Button
            variant="secondary"
            className="my-4 w-full !border-error-200"
            onClick={() => {
              const accept = window.confirm(
                "Are you sure? This will refresh the page and discard your keypair and it will be lost forever."
              );
              if (!accept) {
                return;
              }
              window.localStorage.removeItem(KEY_PAIR_SLUG);
              window.location.reload();
            }}
          >
            Generate New Wallet
          </Button>
        </div>
      </Collapsible>
    </div>
  );
};

export default DevTools;
