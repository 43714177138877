import { Heading, Separator, Text, toolkitCx } from "@sundae/ui-toolkit";
import { FC, useCallback } from "react";
import { animated, useSpring } from "react-spring";
import { useReactQuerySubscription } from "../../hooks/useReactQuerySubscription";
import StatsSkeleton from "./Stats.skeleton";

const Stats: FC = () => {
  // TODO: use _actual_ values
  const { heartbeatsData, fetchingHeartbeats } = useReactQuerySubscription();

  const minutes = (heartbeatsData?.span ?? 0) / 60;
  const normalizedPreview = (heartbeatsData?.previewOrders ?? 0) / minutes;
  const normalizedGummi = (heartbeatsData?.gummiOrders ?? 0) / minutes;

  const previewOrders = useSpring({ val: normalizedPreview, from: { val: 0 } });
  const gummiOrders = useSpring({ val: normalizedGummi, from: { val: 0 } });

  const formatGummiOrders = useCallback(
    (val: number) => {
      const newNumber = Intl.NumberFormat("en", {
        style: "decimal",
        maximumFractionDigits: 0,
      }).format(Math.ceil(val) ?? 0);
      return newNumber;
    },
    [gummiOrders]
  );

  const formatPreviewOrders = useCallback(
    (val: number) => {
      const newNumber = Intl.NumberFormat("en", {
        style: "decimal",
        maximumFractionDigits: 1,
      }).format(val ?? 0);

      return newNumber;
    },
    [previewOrders]
  );

  return (
    <section className="mx-auto flex w-full flex-col justify-center gap-4 lg:flex-row">
      <div className="flex-1 text-center">
        <Heading tag="h3" size="3xl">
          Preview
        </Heading>
        <Text tag="p" size="lg" weight="light" className="mb-6">
          Current Orders Per Minute
        </Text>
        <Text tag="p" size="7xl" weight="bold" className="mt-auto">
          {fetchingHeartbeats && <StatsSkeleton />}
          {!fetchingHeartbeats && previewOrders && (
            <animated.div>{previewOrders.val.to(formatPreviewOrders)}</animated.div>
          )}
        </Text>
      </div>

      <Separator orientation="vertical" className={toolkitCx.layout.background.separator} />

      <div className="flex-1 text-center">
        <Heading className="mx-auto w-fit" gradient tag="h3" size="3xl">
          Hydra
        </Heading>
        <Text tag="p" size="lg" weight="light" className="mb-6">
          Current Orders Per Minute
        </Text>
        <Text tag="p" size="7xl" weight="bold" className="mt-auto">
          {fetchingHeartbeats && <StatsSkeleton />}
          {!fetchingHeartbeats && gummiOrders && (
            <animated.div className="number">{gummiOrders.val.to(formatGummiOrders)}</animated.div>
          )}
        </Text>
      </div>
    </section>
  );
};

export default Stats;
