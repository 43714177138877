import { defaultStyles, Tooltip, TooltipWithBounds } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { CSSProperties, FC, useMemo } from "react";

const {
  theme: { colors },
} = require("@sundae/tailwind-config");

const getTooltipStyles = (isDarkMode: boolean): CSSProperties => ({
  ...defaultStyles,
  background: isDarkMode ? colors.gray[600] : colors.gray,
  borderRadius: "6px",
  color: isDarkMode ? colors.gray[200] : colors.black,
  padding: "0.5rem 1rem",
  zIndex: 50,
});

export interface IChartTooltipProps
  extends Pick<WithTooltipProvidedProps<{}>, "tooltipTop" | "tooltipLeft"> {
  date: string;
  isDarkMode: boolean;
  top: number;
  value: string;
}

const ChartTooltip: FC<IChartTooltipProps> = ({
  date,
  isDarkMode,
  tooltipLeft,
  tooltipTop,
  top,
  value,
  ...props
}) => {
  const key = useMemo(() => Math.random(), []);
  return (
    <>
      <TooltipWithBounds
        {...props}
        key={key}
        top={tooltipTop - 12}
        left={tooltipLeft + 12}
        style={getTooltipStyles(isDarkMode)}
      >
        {value}
      </TooltipWithBounds>
      <Tooltip
        top={top}
        left={tooltipLeft}
        style={{
          ...defaultStyles,
          ...getTooltipStyles(isDarkMode),
          minWidth: 72,
          textAlign: "center",
          transform: "translateX(-50%)",
        }}
      >
        {date}
      </Tooltip>
    </>
  );
};

export default ChartTooltip;
