import { FC } from "react";

import Banner from "../../components/Banner";
import OrdersView from "../../components/OrdersView";
import Stats from "../../components/Stats";
import SwapView from "../../components/SwapView";

const Home: FC = () => {
  return (
    <div className="flex flex-col gap-12">
      <Banner />
      <Stats />

      <div className="flex w-full flex-col items-start justify-between gap-4 lg:flex-row">
        <SwapView />
        <OrdersView />
      </div>
    </div>
  );
};

export default Home;
