import { useEffect, useState } from "react";
import { useLucid } from "./useLucid";
import { useTempWallet } from "./useTempWallet";

export const useBalance = (token: string, decimals: number) => {
  const { lucid } = useLucid();
  const { wallet } = useTempWallet();
  const [balance, setBalance] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!lucid || !wallet) {
      return;
    }

    (async () => {
      setRefreshing(true);
      const utxos = await lucid.wallet.getUtxos();
      const total = utxos.reduce((acc, { assets }) => {
        if (assets?.[token]) {
          return (acc += Number(assets?.[token]?.toString()));
        }

        return acc;
      }, 0);

      setBalance(total / Math.pow(10, decimals));
      setRefreshing(false);
      !initialized && setInitialized(true);
    })();
  }, [lucid, wallet, token]);

  return { balance, refreshing, initialized };
};
