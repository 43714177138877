import { FC, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  createClient,
  defaultExchanges,
  subscriptionExchange,
  Provider as UrqlProvider,
} from "urql";
import { createClient as createGWSClient } from "graphql-ws";

export type TProvidersProps = {
  children: ReactNode;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const wsClient = createGWSClient({
  url: "wss://api.gummi.preview.sundaeswap.finance/query",
});

const client = createClient({
  url: "https://api.gummi.preview.sundaeswap.finance/query",
  exchanges: [
    ...defaultExchanges,
    subscriptionExchange({
      forwardSubscription: (operation) => ({
        subscribe: (sink) => ({
          unsubscribe: wsClient.subscribe(operation, sink),
        }),
      }),
    }),
  ],
});

export const Providers: FC<TProvidersProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <UrqlProvider value={client}>{children}</UrqlProvider>
    </QueryClientProvider>
  );
};

export default Providers;
