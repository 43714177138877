import { Image, Text } from "@sundae/ui-toolkit";
import { ITextProps } from "@sundae/ui-toolkit/dist/types/components/atoms/Text/Text";
import cx from "classnames";
import React from "react";

export interface INoDataIndicatorProps extends Pick<ITextProps, "size"> {
  containerClassName?: string;
  label: string;
  imageClassName?: string;
  imageMaxWidth?: number;
}

const NoDataIndicator: React.FC<INoDataIndicatorProps> = ({
  containerClassName,
  label,
  imageClassName,
  imageMaxWidth = 92,
  size = "sm",
}) => {
  return (
    <div className={cx("flex w-full flex-col items-center gap-2 p-4", containerClassName)}>
      <Image
        src="/static/images/dudes/froyo_confused_sad_400x400.png"
        className={cx(imageClassName)}
        style={{ maxWidth: `${imageMaxWidth}px` }}
      />
      <Text className="text-center" size={size} tag="p">
        {label}
      </Text>
    </div>
  );
};

export default NoDataIndicator;
