import { FC, memo, useEffect, useMemo, useState } from "react";
import {
  ArrowRightIcon,
  Avatar,
  CheckCircledIcon,
  InfoCircledIcon,
  NumberI18N,
  Popover,
  Text,
  toolkitCx,
  Tooltip,
} from "@sundae/ui-toolkit";
import cx from "classnames";
import { useI18N, useTruncateString } from "@sundae/react-hooks";

import { TSwap } from "../../hooks/useReactQuerySubscription";
import { SWAP_ASSETS } from "../../constants/assets";

export const Order: FC<TSwap> = memo(
  ({
    address,
    tokenAAmt,
    tokenA,
    tokenB,
    tokenBAmt,
    timestamp,
    transaction,
    newPrice,
    oldPrice,
  }) => {
    const truncatedAddress = useTruncateString(address, 12, 8);
    const { currentLocale } = useI18N();
    const [flash, setFlash] = useState(true);

    const assetA = useMemo(() => {
      return SWAP_ASSETS[tokenA];
    }, [tokenA]);
    const assetB = useMemo(() => {
      return SWAP_ASSETS[tokenB];
    }, [tokenB]);

    useEffect(() => {
      setTimeout(() => {
        setFlash(false);
      }, 800);
    }, []);

    return (
      <div
        className={cx(
          "flex w-full flex-wrap items-center justify-between gap-4 rounded-lg transition-all hover:opacity-100",
          { "border-success": flash },
          { [toolkitCx.layout.border.default]: !flash },
          { "opacity-50": !flash },
          toolkitCx.layout.border.weight.default,
          toolkitCx.layout.spacing.both.md,
          toolkitCx.layout.background.default
        )}
      >
        <div className="flex items-center gap-3">
          <div className="flex items-center">
            <Avatar src={assetA?.logo} alt={assetA.ticker} size="sm" />
            <div
              className={cx(
                "z-10 -ml-2 flex h-3 w-4 items-center justify-center rounded-lg",
                toolkitCx.layout.background.card
              )}
            >
              <ArrowRightIcon className={cx("h-2 w-2", toolkitCx.layout.text.default)} />
            </div>
            <Avatar className="-ml-2" src={assetB?.logo} alt={assetB.ticker} size="sm" />
          </div>

          <div className="grid">
            <Text tag="p" size="lg" weight="bold">
              Swap
            </Text>
            <Text variant="muted" size="sm" tag="p">
              <NumberI18N
                formatOpts={{
                  maximumFractionDigits: assetA.decimals,
                }}
                variant="muted"
                size="sm"
                value={Number(tokenAAmt) / Math.pow(10, assetB.decimals)}
              />{" "}
              {assetA.ticker} for{" "}
              <NumberI18N
                formatOpts={{
                  maximumFractionDigits: assetB.decimals,
                }}
                variant="muted"
                size="sm"
                value={Number(tokenBAmt) / Math.pow(10, assetB.decimals)}
              />{" "}
              {assetB.ticker}
            </Text>
            <Text variant="muted" size="xs" tag="p">
              Submitted by{" "}
              <Tooltip
                primitiveProps={{ content: { className: "!max-w-none" } }}
                trigger={
                  <Text tag="span" variant="muted" size="xs">
                    {truncatedAddress}
                  </Text>
                }
                tooltipLabel={address}
              />
            </Text>
          </div>
        </div>

        <div className="grid text-right">
          <Text
            className="flex items-center gap-1 lg:justify-end"
            tag="p"
            size="lg"
            variant="success"
          >
            Success <CheckCircledIcon className="text-success" />
          </Text>
          <Text variant="muted" size="sm" tag="p">
            Swapped at {new Date(timestamp.format).toLocaleString(currentLocale)}
          </Text>
          <Popover
            primitiveProps={{
              trigger: {
                asChild: true,
              },
            }}
            trigger={
              <Text
                className="flex cursor-pointer items-center gap-1 lg:justify-end"
                tag="p"
                size="sm"
                variant="primary"
              >
                Transaction Data
                <InfoCircledIcon />
              </Text>
            }
          >
            <div className="flex w-full flex-col items-start justify-start gap-2 text-gray-500">
              <Text tag="h4" weight="bold" size="2xl">
                Tx Details
              </Text>
              <Text tag="p" className="whitespace-pre-wrap break-all">
                Tx Hash: {transaction}
              </Text>
              <Text tag="p">Old Price: {oldPrice}</Text>
              <Text tag="p">New Price: {newPrice}</Text>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
);
