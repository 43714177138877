import { hexToBytes } from "@sundae/cardano-utils";
import { useLocalStorageState } from "@sundae/react-hooks";
import blake from "blakejs";
import { useEffect, useMemo, useState } from "react";
import { useTempKeyPair } from "./useTempKeyPair";

const WALLET_SLUG = "hydra-poc-temp-wallet";

interface IWallet {
  address: string;
  hash: string;
}

export const useTempWallet = () => {
  const [wallet, setWallet] = useLocalStorageState<IWallet>(WALLET_SLUG);
  const [isCreatingWallet, setIsCreatingWallet] = useState(false);
  const { keyPair } = useTempKeyPair();

  const data = useMemo(() => {
    if (keyPair?.publicKey) {
      /**
       * `6` --> payment address only
       * `0` --> testnet/preview
       */
      const hash = blake.blake2bHex(hexToBytes(keyPair.publicKey), null, 224 / 8);
      return {
        blakeAddr: `60${hash}`,
        hash,
      };
    }
  }, [keyPair]);

  useEffect(() => {
    if (!data) return;

    (async () => {
      setIsCreatingWallet(true);

      const { addressToBech32 } = await import("@sundae/serialization");
      setWallet({ address: addressToBech32(data.blakeAddr), hash: data.hash });

      setIsCreatingWallet(false);
    })();
  }, [data]);

  return { wallet, setWallet, isCreatingWallet };
};
