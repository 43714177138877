import {
  Avatar,
  Badge,
  Button,
  Card,
  FractionInput,
  Heading,
  InfoCircledIcon,
  Loader,
  Notice,
  Text,
  toolkitCx,
  Tooltip,
  UpdateIcon,
} from "@sundae/ui-toolkit";
import { FC, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { useI18N } from "@sundae/react-hooks";

import { ADA_METADATA, RBERRY_METADATA } from "../../constants/assets";
import { FRACTION_INPUT_MAX_LENGTH } from "../../constants/swap";
import DevTools from "../DevTools";
import { useSwap } from "../../hooks/useSwap";
import { useLucid } from "../../hooks/useLucid";
import { useReactQuerySubscription } from "../../hooks/useReactQuerySubscription";
import { useBalance } from "../../hooks/useBalance";

const SwapView: FC = () => {
  const { thousandSeparator, decimalSeparator } = useI18N();
  const [fromActive, setFromActive] = useState(false);
  const [toActive, setToActive] = useState(false);
  const [fromValue, setFromValue] = useState<string>();
  const [toValue, setToValue] = useState<string>();
  const [direction, setDirection] = useState(1);
  const { initialized, loading, lucid, connectLucid } = useLucid();
  const { swapAssets, swapping, error, success, setError } = useSwap(lucid);
  const { ordersData } = useReactQuerySubscription(1);
  const fromAsset = useMemo(() => (direction ? ADA_METADATA : RBERRY_METADATA), [direction]);
  const toAsset = useMemo(() => (direction ? RBERRY_METADATA : ADA_METADATA), [direction]);
  const { balance, refreshing } = useBalance(
    direction ? "lovelace" : fromAsset.assetId.replace(".", ""),
    fromAsset.decimals
  );

  const isValidSwap = useMemo(() => fromValue && toValue, [toValue, fromValue]);
  const currentPrice = useMemo(() => {
    const recentOrder = ordersData?.[0];
    if (!recentOrder) {
      return 1;
    }

    const recentFrom =
      recentOrder.tokenA === fromAsset.meta ? recentOrder.tokenAAmt : recentOrder.tokenBAmt;
    const recentTo =
      recentOrder.tokenB === toAsset.meta ? recentOrder.tokenBAmt : recentOrder.tokenAAmt;

    return Number(recentFrom) / Number(recentTo);
  }, [direction, ordersData]);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 5000);
    }
  }, [error]);

  return (
    <div className="flex-1">
      <Card bg="default">
        <div className="flex flex-col gap-6">
          <div className="flex w-full items-end justify-between">
            <Heading tag="h3" weight="medium">
              Swap
            </Heading>

            <Tooltip
              trigger={
                <Text
                  className="flex items-center gap-1 !text-primary dark:!text-primary"
                  tag="p"
                  variant="primary"
                >
                  Market Order <InfoCircledIcon className="h-4 w-4 text-primary" />
                </Text>
              }
              tooltipLabel="A market order executes at the current price."
            />
          </div>

          {loading && (
            <div className="flex items-center justify-center p-4">
              <Loader />
            </div>
          )}
          {!loading && initialized && (
            <>
              <div className="relative flex flex-col gap-4">
                <div
                  className={cx(
                    "flex w-full flex-wrap items-center justify-between gap-4 rounded-lg",
                    toolkitCx.layout.transition.colors,
                    toolkitCx.layout.border.default,
                    toolkitCx.layout.border.hoverable,
                    toolkitCx.layout.border.flashOnFocus,
                    toolkitCx.layout.border.weight.default,
                    toolkitCx.layout.spacing.horizontal.md,
                    toolkitCx.layout.spacing.vertical.lg,
                    toolkitCx.layout.background.default,
                    {
                      "!dark:border-primary-200/70 !border-primary-200/70": fromActive,
                    }
                  )}
                >
                  <div className="absolute -top-[10px] left-2">
                    <Badge size="xs">
                      From ({fromAsset.ticker}) - Balance:
                      {refreshing ? "Updating..." : ` ${fromAsset.symbol}${balance}`}
                    </Badge>
                  </div>

                  <div className="flex w-full flex-nowrap items-center justify-between gap-4">
                    <FractionInput
                      className={cx(
                        "w-1/2 flex-1 bg-transparent text-3xl",
                        toolkitCx.layout.text.default
                      )}
                      decimalScale={fromAsset.decimals}
                      decimalSeparator={decimalSeparator}
                      maxLength={FRACTION_INPUT_MAX_LENGTH}
                      onValueChange={({ value: v }) => {
                        if (fromValue !== v) setFromValue(v);
                      }}
                      onBlur={() => setFromActive(false)}
                      onFocus={() => setFromActive(true)}
                      placeholder={"0.00"}
                      thousandSeparator={thousandSeparator}
                      value={fromValue}
                    />

                    <div
                      className={cx(
                        "flex h-9 items-center gap-2 rounded-lg px-2",
                        toolkitCx.layout.border.default,
                        toolkitCx.layout.background.card,
                        toolkitCx.layout.border.weight.default
                      )}
                    >
                      <Avatar src={fromAsset.logo} alt={fromAsset.ticker} size="sm" />
                      <Text tag="p" weight="bold">
                        {fromAsset.ticker}
                      </Text>
                    </div>
                  </div>
                </div>

                <Button
                  variant="silent"
                  onClick={() => setDirection((oldDir) => Number(!oldDir))}
                  className={cx(
                    "absolute z-10 h-8 w-12 !min-w-0 rounded-lg px-0",
                    toolkitCx.layout.background.card,
                    toolkitCx.layout.border.default,
                    toolkitCx.layout.border.weight.default,
                    toolkitCx.layout.transition.colors,
                    toolkitCx.recipes.center.both()
                  )}
                >
                  <UpdateIcon
                    className={cx(
                      "absolute",
                      toolkitCx.layout.text.default,
                      toolkitCx.recipes.center.both()
                    )}
                  />
                </Button>

                <div
                  className={cx(
                    "flex w-full flex-wrap items-center justify-between gap-4 rounded-lg",
                    toolkitCx.layout.transition.colors,
                    toolkitCx.layout.border.default,
                    toolkitCx.layout.border.hoverable,
                    toolkitCx.layout.border.weight.default,
                    toolkitCx.layout.border.flashOnFocus,
                    toolkitCx.layout.spacing.horizontal.md,
                    toolkitCx.layout.spacing.vertical.lg,
                    toolkitCx.layout.background.default,
                    {
                      "!dark:border-primary-200/70 !border-primary-200/70": toActive,
                    }
                  )}
                >
                  <div className="absolute -top-[10px] left-2">
                    <Badge size="xs">To approximately ({toAsset.ticker})</Badge>
                  </div>

                  <div className="flex w-full flex-nowrap items-center justify-between gap-4">
                    <FractionInput
                      disabled
                      className={cx(
                        "w-1/2 flex-1 bg-transparent text-3xl",
                        toolkitCx.layout.text.muted
                      )}
                      decimalScale={toAsset.decimals}
                      decimalSeparator={decimalSeparator}
                      maxLength={FRACTION_INPUT_MAX_LENGTH}
                      onValueChange={({ value: v }) => {
                        if (toValue !== v) setToValue(v);
                      }}
                      onBlur={() => setToActive(false)}
                      onFocus={() => setToActive(true)}
                      placeholder={"0.00"}
                      thousandSeparator={thousandSeparator}
                      value={(Number(fromValue ?? 0) / currentPrice).toString()}
                    />

                    <div
                      className={cx(
                        "flex h-9 items-center justify-end gap-2 rounded-lg px-2",
                        toolkitCx.layout.border.default,
                        toolkitCx.layout.background.card,
                        toolkitCx.layout.border.weight.default
                      )}
                    >
                      <Avatar src={toAsset.logo} alt={toAsset.ticker} size="sm" />
                      <Text tag="p" weight="bold">
                        {toAsset.ticker}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                disabled={!fromValue || !toValue || swapping}
                onClick={async () => {
                  const payload = {
                    assetAAmount: Number(fromValue),
                    assetBAmount: Number(toValue),
                    assetAMetadata: fromAsset,
                    assetBMetadata: toAsset,
                  };

                  await swapAssets(payload);
                }}
              >
                {isValidSwap ? (
                  swapping ? (
                    <div>Swapping...</div>
                  ) : (
                    <div>Commit Swap</div>
                  )
                ) : (
                  "Enter Amount"
                )}
              </Button>
              {error && <Notice type="error">{error}</Notice>}
              {success && <Notice type="success">Successfully submitted!</Notice>}
            </>
          )}
          {!loading && !initialized && (
            <Button variant="colored" onClick={connectLucid}>
              Generate Temporary Wallet
            </Button>
          )}
        </div>
      </Card>
      {!loading && initialized && <DevTools />}
    </div>
  );
};

export default SwapView;
