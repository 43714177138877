import { createHashHistory, ReactLocation } from "@tanstack/react-location";
import { FC } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import { Routes } from "./pages/RouteProvider";
import Providers from "./Providers";

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <Providers>
      {/*<ErrorLayout>*/}
      <div className="flex gap-4 text-center" role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button
          onClick={() => {
            resetErrorBoundary();
            window.location.reload();
          }}
        >
          Reset
        </button>
      </div>
      {/*</ErrorLayout>*/}
    </Providers>
  );
};

export const App: FC = () => {
  const history = createHashHistory();
  const location = new ReactLocation({ history });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Providers>
        <Routes location={location} />
      </Providers>
    </ErrorBoundary>
  );
};

export default App;
