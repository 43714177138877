import React, { useMemo, useCallback } from "react";
import { max, extent, bisector, min } from "d3-array";
import { scaleTime, scaleLinear } from "@visx/scale";
import { localPoint } from "@visx/event";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { ITokenPrice } from "./Chart";

const bisectDate = bisector<ITokenPrice, Date>((d) => new Date(d.date)).left;

export const useChart = ({
  showTooltip,
  margin,
  getTokenValue,
  data,
  getDate,
  innerHeight,
}: {
  margin?: { top: number; right: number; bottom: number; left: number };
  data: ITokenPrice[];
  getDate: (_d: ITokenPrice) => Date;
  innerHeight: number;
  getTokenValue: (_d: ITokenPrice) => number;
} & Pick<WithTooltipProvidedProps<{}>, "showTooltip">) => {
  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [margin.left, innerWidth + margin.left],
        domain: extent(data, getDate) as [Date, Date],
      }),
    [data, getDate, margin.left]
  );

  const tokenValueScale = useMemo(
    () =>
      scaleLinear({
        range: [innerHeight + margin.top, margin.top],
        domain: [min(data, getTokenValue) || 0, max(data, getTokenValue) || 0],
        nice: true,
      }),
    [innerHeight, margin.top, data, getTokenValue]
  );

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = dateScale.invert(x);
      const index = bisectDate(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      let d = d0;
      if (d1 && getDate(d1)) {
        d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
      }
      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: tokenValueScale(getTokenValue(d)),
      });
    },
    [dateScale, data, getDate, showTooltip, tokenValueScale, getTokenValue]
  );

  return { handleTooltip, dateScale, tokenValueScale };
};
