export enum EPATHS {
  HOME = "/",
  PRICE = "/price",
}

export enum EEX_PATHS {
  PRICE = "https://hydra-poc.solunavax.technology/#/price",
  DISCORD = "https://discord.gg/Sundae",
  LEGAL = "https://www.sundaeswap.finance/terms",
  DISCLAIMER = "https://sundaeswap.finance/terms",
  PRIVACY = "https://sundaeswap.finance/privacy",
  TERMS = "https://sundaeswap.finance/terms",
  TWITTER = "https://twitter.com/SundaeSwap",
  MEDIUM = "https://sundaeswap-finance.medium.com/",
  FAQ = "https://help.sundaeswap.finance/en/",
  ABOUT = "https://www.youtube.com/watch?v=vJdLO4RXZ4s",
  WEBSITE = "https://www.sundaeswap.finance/",
}
