import { InitOptions } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
const { i18next, i18nDefaultOptions } = require("@sundae/i18n-config");

export const languages = [
  {
    locale: "ar",
    localeString: "عربي",
  },
  {
    locale: "de",
    localeString: "Deutsch",
  },
  {
    locale: "en",
    localeString: "English",
  },
  {
    locale: "es",
    localeString: "Español",
  },
  {
    locale: "fr",
    localeString: "Français",
  },
  {
    locale: "it",
    localeString: "Italiano",
  },
  {
    locale: "ja",
    localeString: "日本語",
  },
  {
    locale: "nl",
    localeString: "Nederlands",
  },
  {
    locale: "pt",
    localeString: "Português",
  },
  {
    locale: "ru",
    localeString: "Русский",
  },
  {
    locale: "th",
    localeString: "ภาษาไทย",
  },
];

const options: InitOptions = {
  ...i18nDefaultOptions(languages),
  ns: ["common"],
  defaultNS: "common",
};

i18next
  // setup your `i18n` to load the proper files
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./translations/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((_error) => {
          // TODO: raygun
        });
    })
  )
  .init(options);

export default i18next;
