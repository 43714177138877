import { useSubscription } from "urql";
import { v4 } from "uuid";
import gql from "graphql-tag";
import { useTempWallet } from "./useTempWallet";

const heartbeats = gql`
  subscription Heartbeat {
    heartbeat {
      span
      previewOrders
      gummiOrders
    }
  }
`;
const orders = `
  subscription Orders {
    listenForSwaps {
      address
      timestamp {
        format
      }
      oldPrice
      newPrice
      tokenA
      tokenAAmt
      tokenB
      tokenBAmt
      transaction
    }
  }
`;

const myOrders = `
  subscription Orders($address: String!) {
    listenForSwaps(address: $address) {
      address
      timestamp {
        format
      }
      oldPrice
      newPrice
      tokenA
      tokenAAmt
      tokenB
      tokenBAmt
      transaction
    }
  }
`;

export type THeartbeat = { span: number; previewOrders: number; gummiOrders: number };
export type TSwap = {
  address: string;
  tokenAAmt: string;
  tokenBAmt: string;
  timestamp: {
    format: string;
  };
  oldPrice: number;
  newPrice: number;
  tokenB: string;
  tokenA: string;
  transaction: string;
  key: string;
};

export const useReactQuerySubscription = (maxSwapRecords = 20) => {
  const { wallet } = useTempWallet();

  const handleHeartbeat = (prev: THeartbeat, response: { heartbeat: THeartbeat }) =>
    response?.heartbeat;

  const handleSwaps = (prev: Array<TSwap> = [], response: { listenForSwaps: TSwap }) => {
    return [{ ...response?.listenForSwaps, key: v4() }, ...prev];
  };

  const [{ data: heartbeatsData, fetching: fetchingHeartbeats, error: fetchingHeartbeatsError }] =
    useSubscription({ query: heartbeats }, handleHeartbeat);
  const [{ data: ordersData, fetching: fetchingOrders, error: fetchingOrdersError }] =
    useSubscription({ query: orders }, handleSwaps);
  const [{ data: myOrdersData, fetching: fetchingMyOrders, error: fetchingMyOrdersError }] =
    useSubscription({ query: myOrders, variables: { address: wallet?.address } }, handleSwaps);

  return {
    fetchingHeartbeats,
    fetchingOrders,
    fetchingMyOrders,
    fetchingHeartbeatsError,
    fetchingOrdersError,
    fetchingMyOrdersError,
    heartbeatsData,
    ordersData: ordersData?.slice(0, maxSwapRecords),
    myOrdersData,
  };
};
