import { useI18N, useTheme } from "@sundae/react-hooks";
import { FC, PropsWithChildren } from "react";
import cx from "classnames";
import ReactContentLoader, { IContentLoaderProps } from "react-content-loader";
const {
  theme: { colors },
} = require("@sundae/tailwind-config");

const ContentLoader: FC<PropsWithChildren<IContentLoaderProps>> = ({
  children,
  ...contentLoaderProps
}) => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const { t } = useI18N();

  return (
    <ReactContentLoader
      backgroundColor={isDarkMode ? colors.gray[700] : colors.gray.DEFAULT}
      foregroundColor={isDarkMode ? colors.gray[600] : colors.gray[200]}
      className={cx("w-full", contentLoaderProps.className)}
      title={t("loading")}
      speed={1.6}
      {...contentLoaderProps}
    >
      {children}
    </ReactContentLoader>
  );
};

export default ContentLoader;
