/* eslint-disable */
import { bytesToHex, hexToBytes } from "@sundae/cardano-utils";
import { ProtocolParameters, Lucid, Provider, WalletApi } from "lucid-cardano";
import { useCallback, useEffect, useState } from "react";

import { HYDRA_PROTOCOL_PARAMETERS } from "../constants/swap";
import { getHydraSdk } from "../gql/client/hydraSdk";
import { useTempWallet } from "./useTempWallet";
import { useTempKeyPair } from "./useTempKeyPair";

class MockBlockfrost {
  address: string;
  constructor(address: string) {
    this.address = address;
  }

  static new(address: string): Provider {
    return new this(address) as unknown as Provider;
  }

  async getProtocolParameters(): Promise<ProtocolParameters> {
    return HYDRA_PROTOCOL_PARAMETERS as unknown as ProtocolParameters;
  }
}

class MockWalletApi {
  address: string;
  privateKey: string;
  publicKey: string;

  constructor(address: string, privateKey: string, publicKey: string) {
    this.address = address;
    this.privateKey = privateKey;
    this.publicKey = publicKey;
  }

  static new(address: string, privateKey: string, publicKey: string): WalletApi {
    return new this(address, privateKey, publicKey) as unknown as WalletApi;
  }

  async getNetworkId() {
    return 0;
  }

  async getUtxos(): Promise<string[] | undefined> {
    const { getUtxos } = await getHydraSdk();
    const { utxos } = await getUtxos({
      // Hook up later
      address: this.address,
    });

    const S = await import("@dcspark/cardano-multiplatform-lib-browser");

    const UTXOS = utxos.map((utxo) => {
      const multiAsset = S.MultiAsset.new();
      const value = S.Value.new(S.BigNum.from_str(utxo.lovelace));
      for (const asset of utxo.assets) {
        const [policyId, assetName] = asset.assetId.split(".");
        multiAsset.set_asset(
          S.ScriptHash.from_hex(policyId),
          S.AssetName.new(hexToBytes(assetName)),
          S.BigNum.from_str(asset.quantity)
        );
      }
      value.set_multiasset(multiAsset);
      return bytesToHex(
        S.TransactionUnspentOutput.new(
          S.TransactionInput.new(
            S.TransactionHash.from_bytes(hexToBytes(utxo.transactionHash)),
            S.BigNum.from_str(utxo.index.toString())
          ),
          S.TransactionOutput.new(S.Address.from_bech32(utxo.address), value)
        ).to_bytes()
      );
    });

    return UTXOS;
  }

  async getUsedAddresses() {
    const S = await import("@dcspark/cardano-multiplatform-lib-browser");
    const addr = bytesToHex(S.Address.from_bech32(this.address).to_bytes());
    return [addr];
  }

  async signTx(txComplete: string): Promise<string> {
    const { blake2bHex } = await import("blakejs");
    const ed = await import("@noble/ed25519");
    const S = await import("@dcspark/cardano-multiplatform-lib-browser");
    const tx = S.Transaction.from_bytes(hexToBytes(txComplete));

    const hash = blake2bHex(tx.body().to_bytes(), null, 256 / 8);
    const signedBytes = await ed.sign(hash, this.privateKey);
    const tws = S.TransactionWitnessSetBuilder.new();
    const publicKeyBytes = hexToBytes(`5820${this.publicKey}`);

    tws.add_vkey(
      S.Vkeywitness.new(
        S.Vkey.from_bytes(publicKeyBytes),
        S.Ed25519Signature.from_bytes(signedBytes)
      )
    );
    const witnessHex = bytesToHex(tws.build().to_bytes());
    console.log({ hash, pk: this.publicKey, witnessHex, txComplete });
    return witnessHex;
  }

  async submitTx(cborHex: string) {
    const { submitTx } = await getHydraSdk();
    const res = await submitTx({
      cborHex: cborHex,
    });

    return res.submit;
  }
}

export const useLucid = () => {
  const { wallet, isCreatingWallet } = useTempWallet();
  const { keyPair } = useTempKeyPair();
  const [loading, setLoading] = useState(isCreatingWallet);
  const [lucid, setLucid] = useState<Lucid>();
  const [initialized, setInitialized] = useState(false);

  const connectLucid = useCallback(async () => {
    if (isCreatingWallet || !wallet) {
      return;
    }

    if (lucid) {
      console.log("already connected");
      return;
    }

    if (!lucid) {
      setLoading(true);
      const { Lucid: LucidClient } = await import("lucid-cardano");
      const newClient = await LucidClient.new(MockBlockfrost.new(wallet.address));
      newClient.selectWallet(
        MockWalletApi.new(wallet.address, keyPair.privateKey, keyPair.publicKey)
      );
      newClient.network = "Preview";
      setInitialized(true);

      setLucid(newClient);
      setLoading(false);
    }
  }, [lucid, isCreatingWallet, wallet?.address]);

  useEffect(() => {
    if (!lucid && wallet) {
      connectLucid();
    }

    if (!wallet) {
      setLucid(null);
    }
  }, [lucid, wallet]);

  return {
    connectLucid,
    lucid,
    initialized,
    loading,
  };
};
