import { useTheme } from "@sundae/react-hooks";
import { curveMonotoneX } from "@visx/curve";
import { LinearGradient } from "@visx/gradient";
import { AreaClosed, Bar, Line } from "@visx/shape";
import { withTooltip } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { timeFormat } from "d3-time-format";
import { FC, useMemo } from "react";

import ChartTooltip from "../ChartTooltip";
import { useChart } from "./useChart";

export interface ITokenPrice {
  date: string;
  price: number;
}

const {
  theme: { colors },
} = require("@sundae/tailwind-config");

export const chartColorLight = colors.primary[200];
export const chartColorDark = colors.primary[300];

const formatDate = timeFormat("%a %b %e %X");
const getDate = (d: ITokenPrice) => new Date(d.date);
const getTokenValue = (d: ITokenPrice) => d?.price || 0;

export interface IAreaProps extends Partial<WithTooltipProvidedProps<ITokenPrice>> {
  data: ITokenPrice[];
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
}

const PortfolioChart: FC<IAreaProps> = ({
  data,
  width,
  height,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
  showTooltip,
  hideTooltip,
  tooltipData,
  tooltipTop = 0,
  tooltipLeft = 0,
}) => {
  const { theme } = useTheme();
  const isDarkMode = useMemo(() => theme === "dark", [theme]);
  const innerHeight = height - margin.top - margin.bottom;
  const { handleTooltip, dateScale, tokenValueScale } = useChart({
    showTooltip,
    margin,
    getTokenValue,
    data,
    getDate,
    innerHeight,
  });

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;

  return (
    <>
      <div className="animate-dialog-overlay-show">
        <svg width={width} height={height}>
          <rect x={0} y={0} width={width} height={height} fill="url(#area-background-gradient)" />
          <LinearGradient
            id="area-gradient"
            from={chartColorLight}
            to={chartColorDark}
            toOpacity={0}
          />

          <AreaClosed<ITokenPrice>
            data={data}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => tokenValueScale(getTokenValue(d)) ?? 0}
            yScale={tokenValueScale}
            strokeWidth={1}
            stroke="url(#area-gradient)"
            fill="url(#area-gradient)"
            curve={curveMonotoneX}
          />

          <Bar
            x={margin.left}
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />

          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={isDarkMode ? colors.gray[600] : colors.gray[300]}
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill={colors.black}
                fillOpacity={0.1}
                stroke={colors.black}
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={isDarkMode ? colors.gray[600] : colors.gray[300]}
                stroke={colors.primary.DEFAULT}
                strokeWidth={1}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>

        {tooltipData && (
          <ChartTooltip
            date={formatDate(getDate(tooltipData))}
            isDarkMode={isDarkMode}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            top={innerHeight + margin.top - 14}
            value={`₳${getTokenValue(tooltipData)}`}
          />
        )}
      </div>
    </>
  );
};

export default withTooltip<IAreaProps, ITokenPrice>(PortfolioChart);
