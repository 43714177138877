import { AssetAmount } from "@sundae/asset";
import { useI18N } from "@sundae/react-hooks";
import {
  ArrowRightIcon,
  Avatar,
  Balance,
  CheckCircledIcon,
  DateI18N,
  NumberI18N,
  Table,
  Text,
  toolkitCx,
} from "@sundae/ui-toolkit";
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  TableOptions,
} from "@tanstack/react-table";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import cx from "classnames";
import { FC, useMemo, useState } from "react";

import Chart from "../../components/Chart";
import ChartSkeleton from "../../components/Chart/Chart.skeleton";
import { ADA_METADATA, RBERRY_METADATA, SWAP_ASSETS } from "../../constants/assets";
import { TSwap, useReactQuerySubscription } from "../../hooks/useReactQuerySubscription";
import { fromLovelace } from "../../utils/conversions";

const Price: FC = () => {
  const { ordersData = [], fetchingOrders, fetchingOrdersError } = useReactQuerySubscription(200);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { currentLocale } = useI18N();

  const data = useMemo(
    () =>
      ordersData
        .map(({ newPrice, timestamp }) => ({
          price: AssetAmount.fromValue(newPrice, 6).value.toNumber(),
          date: timestamp.format,
        }))
        .reverse(),
    [ordersData]
  );

  const latestPrice = ordersData[0]?.newPrice || 0;

  const columns = useMemo<ColumnDef<TSwap>[]>(
    () => [
      {
        header: "Assets",
        accessorKey: "tokenA",
        cell: ({ row: { original } }) => {
          const { tokenA, tokenB } = original;
          const { logo: logoA, ticker: tickerA } = SWAP_ASSETS[tokenA] || {};
          const { logo: logoB, ticker: tickerB } = SWAP_ASSETS[tokenB] || {};

          return (
            <div className="flex items-center">
              <Avatar src={logoA} alt={tickerA} size="sm" />
              <div
                className={cx(
                  "z-10 -ml-2 flex h-3 w-4 items-center justify-center rounded-lg",
                  toolkitCx.layout.background.card
                )}
              >
                <ArrowRightIcon className={cx("h-2 w-2", toolkitCx.layout.text.default)} />
              </div>
              <Avatar className="-ml-2" src={logoB} alt={tickerB} size="sm" />
            </div>
          );
        },
      },
      {
        header: "Operation",
        id: "operation",
        cell: () => (
          <Text tag="span" size="xs">
            Swap
          </Text>
        ),
      },
      {
        header: "Submitted by",
        accessorKey: "address",
        cell: ({ row: { original } }) => {
          const { address } = original;

          return (
            <Text tag="span" variant="muted" size="xs">
              {address}
            </Text>
          );
        },
      },
      {
        header: "Price",
        accessorKey: "newPrice",
        cell: ({ row: { original } }) => {
          const { tokenA, tokenB, tokenAAmt, tokenBAmt } = original;
          const { ticker: tickerA } = SWAP_ASSETS[tokenA] || {};
          const { ticker: tickerB } = SWAP_ASSETS[tokenB] || {};

          return (
            <Text variant="muted" size="xs" tag="p">
              {tokenA === "ada" ? "₳" : ""}
              <NumberI18N
                variant="muted"
                size="xs"
                value={fromLovelace(Number(tokenAAmt), ADA_METADATA.decimals)}
              />{" "}
              {tickerA === "ADA" ? "" : tickerA} for {tokenB === "ada" ? "₳" : ""}
              <NumberI18N
                variant="muted"
                size="xs"
                value={fromLovelace(Number(tokenBAmt), RBERRY_METADATA.decimals)}
              />{" "}
              {tickerB === "ADA" ? "" : tickerB}
            </Text>
          );
        },
      },
      {
        header: "Swapped at",
        accessorKey: "timestamp.format",
        cell: ({ row: { original } }) => {
          const { timestamp } = original;

          return (
            <DateI18N
              size="xs"
              variant="muted"
              locale={currentLocale}
              value={new Date(timestamp.format)}
            />
          );
        },
      },
      {
        header: "Status",
        id: "status",
        cell: () => (
          <Text
            className="flex items-center gap-1 lg:justify-end"
            tag="p"
            size="xs"
            variant="success"
          >
            Success <CheckCircledIcon className="text-success" />
          </Text>
        ),
      },
    ],
    []
  );

  const tableOptions: TableOptions<TSwap> = {
    data: ordersData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  };

  return (
    <div className="flex flex-col gap-12">
      <div
        className={cx(
          "h-fit w-full rounded-lg",
          toolkitCx.layout.border.default,
          toolkitCx.layout.border.weight.default
        )}
      >
        <div className="relative z-20 h-[26rem]">
          <div className="absolute top-4 left-4 z-10 grid gap-1">
            <div className="flex items-center gap-2">
              <Avatar src={RBERRY_METADATA.logo} alt={RBERRY_METADATA.ticker} size="sm" />
              <Text tag="h2" size="2xl">
                {RBERRY_METADATA.ticker}
              </Text>
            </div>

            <Balance
              abbreviate={false}
              showSymbol
              value={AssetAmount.fromValue(latestPrice, 6)}
              size="lg"
            />
          </div>

          <ParentSize>
            {({ width, height }) =>
              // show loading animation until we have sufficient amount of data to render
              fetchingOrders || data.length < 5 ? (
                <ChartSkeleton
                  width={width}
                  height={height}
                  error={fetchingOrdersError}
                  showLoadingAnimation={(fetchingOrders || data.length < 5) && !fetchingOrdersError}
                />
              ) : (
                <Chart
                  width={width}
                  height={height}
                  data={data}
                  margin={{ top: 100, right: 0, bottom: 0, left: 0 }}
                />
              )
            }
          </ParentSize>
        </div>
        <div className="h-[26rem] overflow-auto">
          <Table
            bodyClassName="text-sm"
            containerClassName="border-t border-t-gray-300 dark:border-t-gray-600"
            headersClassName="text-xs"
            isLoading={fetchingOrders}
            isSortable
            tableOptions={tableOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default Price;
