import { GraphQLClient } from "graphql-request";
import * as hydra from "../generated/hydra.sdk";

const lazyInit = <T>(initializer: () => Promise<T>): (() => Promise<T>) => {
  let value: T;
  return async () => {
    if (value === undefined) {
      value = await initializer();
    }
    return value;
  };
};

export const getHydraGraphqlClient = lazyInit(
  async () => new GraphQLClient(window.__APP_CONFIG.apiUrls.hydra)
);

export const getHydraSdk = lazyInit(() => getHydraGraphqlClient().then(hydra.getSdk));
