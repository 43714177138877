import { ExternalLink } from "@sundae/ui-toolkit";
import cx from "classnames";
import { FC, PropsWithChildren } from "react";

import { EEX_PATHS } from "../../constants/paths";
import { useTempWallet } from "../../hooks/useTempWallet";

const Layout: FC<PropsWithChildren<{}>> = ({ children }): JSX.Element => {
  useTempWallet();

  return (
    <>
      <main className="relative h-full overflow-hidden">
        <section
          className={cx(
            "mt-24 w-full px-6",
            "md:mb-0",
            "lg:relative lg:mx-auto lg:mt-48 lg:max-w-screen-xl"
          )}
        >
          {children}
        </section>
      </main>
      <footer
        className={cx(
          "mb-4 mt-24 flex w-full flex-col gap-24 px-6 text-center",
          "lg:mx-auto lg:max-w-screen-xl"
        )}
      >
        <ExternalLink
          className="self-start"
          arrow="top-right"
          href={EEX_PATHS.LEGAL}
          target="_blank"
          rel="noreferrer noopener"
          variant="muted"
        >
          Legal
        </ExternalLink>
      </footer>

      <div className="fixed top-0 left-0 z-[-2] h-full w-full bg-gradient-to-t from-white to-gray-300 dark:from-gray-600 dark:via-gray-700 dark:to-gray-800" />
    </>
  );
};

export default Layout;
