import { Heading, Text } from "@sundae/ui-toolkit";
import { FC } from "react";

const Banner: FC = (): JSX.Element => {
  return (
    <section className="mx-auto grid gap-4 text-center">
      <Heading tag="h2" size="6xl">
        Hello,{" "}
        <span className="relative">
          <Text tag="span" size="6xl" gradient weight="bold">
            Hydra
          </Text>
          <Text
            tag="span"
            size="sm"
            weight="normal"
            className="z-2 absolute top-0 right-0 uppercase italic"
          >
            Turbo
          </Text>
        </span>
        <Text className="hidden lg:inline" tag="span" size="5xl">
          {" "}
          👋🏻
        </Text>
        <Text className="inline lg:hidden" tag="span" size="6xl">
          !
        </Text>
      </Heading>

      <Text tag="p" size="lg" className="mx-auto max-w-screen-md">
        A visual comparison of transaction throughput on Cardano testnet and our unique L2 solution,
        powered by Hydra. The data below is refreshed every time there's a new block, and signifies
        the average orders-per-second of each network.
      </Text>
    </section>
  );
};

export default Banner;
